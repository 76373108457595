import axios from 'axios';
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Images from '../Comman Components/Images';
import PinImage from '../Comman Components/Pinterest Componets/PinImage';

export default function Approval() {
    const location = useLocation();
    const navigate = useNavigate();

    const Post = (data) => {
        navigate('/Dashboard/PublishPost', { state: { PublishData: data } })
    }

    const GetUserName = async (userID) => {
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        await axios.post(`${process.env.REACT_APP_BACKEND_API}/facebook/page`, {
            user_id: userID
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                console.log(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // useEffect(() => {
    //     console.log("data", (location.state.genData.GroupData))
    // }, [])
    return (
        <div>
            <div className='approval_header'>
                <h4>Need Approval</h4>
            </div>

            <table className='filter_table'>
                <tr>
                    <td>
                        <label>Draft Type</label>
                        <select>
                            <option>Viewing all</option>
                        </select>
                    </td>

                    <td>
                        <label>Profiles</label>
                        <select>
                            <option>Viewing all</option>
                        </select>
                    </td>

                    <td>
                        <label>Tags</label>
                        <select>
                            <option>Viewing all</option>
                        </select>
                    </td>

                    <td>
                        <label>Authors</label>
                        <select>
                            <option>Viewing all</option>
                        </select>
                    </td>
                </tr>
            </table>

            <div className='sort_menu'>
                <label>
                    Sort by
                </label>

                <select>
                    <option>Last Modified Newest</option>
                </select>
            </div>


            {
                location.state != null
                    ?
                    (location.state.genData.GroupData).map((elem) => {
                        console.log(elem)
                        return (
                            <div className='post_section'>
                                <div>
                                    <div className={elem.scheduledTime?'post_Scheduled align-items-center':'post_header align-items-center'}>
                                        <div className='d-flex align-items-center w-75'>
                                            <div>
                                                {
                                                    elem.platform == "FACEBOOK"
                                                        ?
                                                        <img src={`https://graph.facebook.com/${elem.user_id}/picture?return_ssl_resources=true`} className='filter_profile_img' />
                                                        :
                                                        elem.platform == "INSTAGRAM"
                                                            ?
                                                            <>
                                                                {<Images Id={elem.platform_id} />}
                                                            </>
                                                            :
                                                            <PinImage Id={elem.platform_id} />
                                                }
                                            </div>

                                            <div style={{ width: '70%', marginLeft: '20px' }}>
                                                {
                                                    location.state != null
                                                        ?
                                                        <div>
                                                            {
                                                                elem.platform == "INSTAGRAM"
                                                                    ?
                                                                    <i class="fa-brands fa-instagram"></i>
                                                                    :
                                                                    null

                                                            }

                                                            {
                                                                elem.platform == "FACEBOOK"
                                                                    ?
                                                                    <i class="fa-brands fa-facebook"></i>
                                                                    :
                                                                    null

                                                            }
                                                            <label style={{ marginLeft: '10px' }}>
                                                                Gold’s Gym Jaipur
                                                            </label>
                                                        </div>
                                                        :
                                                        null

                                                }

                                                <label className='status'>
                                                    {
                                                        elem.scheduledTime
                                                        ?
                                                        "Scheduled Post"
                                                        :
                                                        "Posted"
                                                    }
                                                </label>
                                            </div>
                                        </div>

                                        <div className='date_section'>
                                            <label>
                                                {
                                                    elem.date
                                                        ?
                                                        ((elem).date).split("T")[0]
                                                        :
                                                        ((elem).scheduledTime).split("T")[0]
                                                }
                                            </label>
                                        </div>

                                    </div>
                                </div>

                                <div className='post_details'>
                                    <p>
                                        {
                                            elem.message
                                        }
                                    </p>

                                    <div className='post_photo'>
                                        {
                                            location.state != null
                                                ?
                                                <>
                                                    {
                                                        elem.media_url
                                                            ?
                                                            <img src={elem.media_url} />
                                                            :
                                                            (elem.image_url).map((photoelement) => {
                                                                return (
                                                                    // <img src={require('../Assets/Mask2.png')} />
                                                                    <img src={photoelement} />

                                                                )
                                                            })
                                                    }
                                                </>
                                                :
                                                null
                                        }
                                        {/* <img src={require('../Assets/Mask group.png')} />

                        <img src={require('../Assets/Mask2.png')} />

                        <img src={require('../Assets/Mask3.png')} /> */}
                                    </div>
                                </div>

                                <div className='post_footer d-flex align-items-center justify-content-between'>
                                    <div className='d-flex align-items-center'>
                                        <img src={require('../Assets/Postfooter.png')} />

                                        <label>
                                            by Author/ Space Owl
                                        </label>
                                    </div>

                                    <div>
                                        <button className='btn' onClick={() => { Post(location.state.genData) }}>
                                            <i class="fa-solid fa-share"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                    :
                    null
            }

        </div>
    )
}

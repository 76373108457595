import React from 'react'
import { useEffect, useState } from 'react'
import GrpImg from '../Assets/grp_img.png'
import ProfileImg from '../Assets/grp_profile.png'
import { Modal, message } from 'antd'
import axios from 'axios'
import FromExisting from './FromExisting'
import { Linkedin, LoginFb, LoginYT, FacebookPages, LoginPintrest, InstagramLogin } from './Comman Methods/Connectprofile';
import Cookies from 'js-cookie';
import ExpandProfile from './ExpandProfile'
import { useNavigate } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import Images from '../Comman Components/Images'
import PinImage from '../Comman Components/Pinterest Componets/PinImage'

export default function GroupsProfile() {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [groupData, setGroupData] = useState(false);
    const [singleProfile, setSingleProfile] = useState([]);
    const [platform, setPlatform] = useState();
    const [isModalNew, setIsModalNew] = useState(false);
    const [GroupLinks, setGroupLink] = useState([]);
    const [GroupName, setGroupName] = useState();
    const [Groups, setGroups] = useState(null);
    const [profile, setProfile] = useState();
    const [Expand, setExpand] = useState(false);
    const [SelectedGroup, setSelectedGroup] = useState();
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#0B968F' }} spin />;


    const navigate = useNavigate();

    const GetProfile = () => {
        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        axios.get(`${process.env.REACT_APP_BACKEND_API}/profiles/get-profile`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                // setProfile(res.data.profiles)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const showModalNew = () => {
        setIsModalNew(true);
    };

    // Get Page Details
    const DataStore = async (data) => {
        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        if (data.platform == "FACEBOOK") {
            singleProfile.length = 0;
            await Promise.all(
                (data.page_details).map(async (elem, index) => {
                    axios.post(`${process.env.REACT_APP_BACKEND_API}/facebook/page`, {
                        "pageId": elem.pageId
                    }, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        }
                    })
                        .then((res) => {
                            singleProfile.push(res.data);
                            setTimeout(() => {
                                setGroupData(true);
                            }, 500)
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
            )
        }
        else if (data.platform === "INSTAGRAM") {
            singleProfile.length = 0;
            await Promise.all(
                (data.page_details).map(async (elem, index) => {
                    axios.post(`${process.env.REACT_APP_BACKEND_API}/instagram/profile`, {
                        insta_id: elem.accountId,
                    }, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    })
                        .then((res) => {
                            // console.log(res.data.profile_picture_url)
                            singleProfile.push({
                                access_token: elem.accessToken,
                                id: elem.accountId,
                                name: res.data.name,
                                picture: {
                                    data: {
                                        height: '50',
                                        url: res.data.profile_picture_url,
                                        width: '50'
                                    }
                                }
                            })

                            setTimeout(() => {
                                setGroupData(true);
                            }, 500)
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
            )

        }
    }

    const DefaultAccount = async (accountData) => {
        console.log(accountData)
        if (accountData.platform == "FACEBOOK") {
            Cookies.set('Oolook_default', JSON.stringify(accountData.platform_pageId));
        }
        else if (accountData.platform == "INSTAGRAM") {
            Cookies.set('Oolook_default_Insta', JSON.stringify(accountData.platform_id));
        }
    }

    const CheckDefault = (checkData) => {
        if (platform == "FACEBOOK") {
            if (Cookies.get('Oolook_default') != undefined) {
                return JSON.parse(Cookies.get('Oolook_default')) == checkData.id
            }
        }
        else if (platform == "INSTAGRAM") {
            if (Cookies.get('Oolook_default_Insta') != undefined) {
                return JSON.parse(Cookies.get('Oolook_default_Insta')) == checkData.id
            }
        }
    }

    const CreateGroup = () => {
        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        axios.post(`${process.env.REACT_APP_BACKEND_API}/group/create`, {
            groupName: GroupName,
            connectedAccounts: GroupLinks
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                message.success("Group Created");
            })
            .catch((err) => {
                console.log(err);
                message.error("There is some error try again");
            })
    }

    const GetGroups = () => {
        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        axios.get(`${process.env.REACT_APP_BACKEND_API}/group/get`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setGroups(res.data.groups)
                setSelectedGroup(res.data.groups[0].name)
                if (localStorage.getItem("oolook_default_group") == undefined) {
                    GetGroupDetails(res.data.groups[0].name)
                } else {
                    GetGroupDetails(localStorage.getItem("oolook_default_group"))
                }

                // setSelectedGroup()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const GetGroupDetails = (Name) => {

        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }


        axios.post(`${process.env.REACT_APP_BACKEND_API}/group/get-particular`, {
            groupName: Name
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setProfile(res.data.group.connected_accounts)
                localStorage.setItem("oolook_default_group", Name)

                // if user vist first time on the page then default group is selected

                if (localStorage.getItem("selected_group") == undefined) {
                    localStorage.setItem("selected_group", Name)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const ConnectPlatformView = () => {
        return (
            <>
                <div className='grp-modal-head mt-3'>Select a network to connect a profile</div>
                <span>
                    If you have several accounts to connect, don’t worry  we’ll bring you back to this screen after each profile is set up.
                </span>

                <div className='d-flex flex-column w-50 mt-5'>
                    <label className='Group_label'>
                        In Group
                    </label>

                    <select className='Group_dropdown' value={SelectedGroup} onChange={(e) => { localStorage.setItem("selected_group", e.target.value); setSelectedGroup(e.target.value) }}>
                        {
                            Groups != null
                                ?
                                Groups.map((elem) => {
                                    return (
                                        <option value={elem.name}>{elem.name}</option>
                                    )
                                })
                                :
                                null
                        }
                    </select>
                </div>

                <div className='modalgrp-div'>
                    <div className='modal-card d-flex flex-column justify-content-around'>
                        <div className='card-img-div'>
                            <img src={require('../Assets/Icons/Facebook.png')} className='modal-img' />
                            <div className='modal-text'>Facebook</div>
                        </div>

                        <div className='modal-connect'>
                            <button className='connect-btn' onClick={() => {
                                LoginFb()
                            }}>Connect</button>
                        </div>
                    </div>

                    <div className='modal-card d-flex flex-column justify-content-around'>
                        <div className='card-img-div'>
                            <img src={require('../Assets/Icons/Instagram.png')} className='modal-img' />
                            <div className='modal-text'>Instagram</div>
                        </div>

                        <div className='modal-connect'>
                            <button className='connect-btn' onClick={() => { InstagramLogin() }}>Connect</button>
                        </div>
                    </div>

                    <div className='modal-card d-flex flex-column justify-content-around'>
                        <div className='card-img-div'>
                            <img src={require('../Assets/Icons/Pintrest.png')} className='modal-img' />
                            <div className='modal-text'>Pinterest</div>
                        </div>

                        <div className='modal-connect'>
                            <button className='connect-btn' onClick={() => { LoginPintrest() }}>Connect</button>
                        </div>
                    </div>

                    <div className='modal-card d-flex flex-column justify-content-around'>
                        <div className='card-img-div'>
                            <img src={require('../Assets/Icons/GB.png')} className='modal-img' />
                            <div className='modal-text'>Google My Business</div>
                        </div>

                        <div className='modal-connect'>
                            <button className='connect-btn'>Connect</button>
                        </div>
                    </div>
                </div>

                <div className='modalgrp-div2 d-flex justify-content-center'>
                    <div className='modal-card d-flex flex-column justify-content-around'>
                        <div className='card-img-div'>
                            <img src={require('../Assets/Icons/Twitter.png')} className='modal-img' />
                            <div className='modal-text'>X</div>
                        </div>

                        <div className='modal-connect'>
                            <button className='connect-btn'>Connect</button>
                        </div>
                    </div>

                    <div className='modal-card d-flex flex-column justify-content-around'>
                        <div className='card-img-div'>
                            <img src={require('../Assets/Icons/LinkedIn.png')} className='modal-img' />
                            <div className='modal-text'>Linkdin</div>
                        </div>

                        <div className='modal-connect'>
                            <button className='connect-btn' onClick={() => Linkedin()}>Connect</button>
                        </div>
                    </div>

                    <div className='modal-card d-flex flex-column justify-content-around'>
                        <div className='card-img-div'>
                            <img src={require('../Assets/Icons/Youtube.png')} className='modal-img' />
                            <div className='modal-text'>YouTube</div>
                        </div>

                        <div className='modal-connect'>
                            <button className='connect-btn' onClick={() => { LoginYT() }}>Connect</button>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const DeleteSingleProfile = (pagedetails) => {
        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        axios.post(`${process.env.REACT_APP_BACKEND_API}/group/delete-profile`, {
            platform_id: pagedetails.platform_id
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                GetGroups()
            })
            .catch((err) => {
                console.log(err)
            })
    }



    useEffect(() => {
        GetProfile()
        GetGroups();
    }, [])

    return (
        <>
            {/* Connect Profile Modal */}
            <>
                <Modal title="Connect a Profile" open={isModalOpen} footer={null} onCancel={() => { setIsModalOpen(false); GetProfile() }}>
                    <ConnectPlatformView />
                </Modal>
            </>

            {/* second modal  */}

            <>
                <Modal open={isModalNew} footer={null} onCancel={() => { setIsModalNew(false) }}>
                    <div className='invite-head'>Create New Group</div>
                    <hr />

                    <div>Groups help you organize profiles and team members for your company. You can create a group with a new or existing profile.</div>

                    <div>
                        <input className='grp-input' onChange={(e) => { setGroupName(e.target.value) }} placeholder='Name of Group' />
                    </div>

                    <div className='d-flex w-100'>
                        <span className='w-100 tab_btn active_tab'>
                            From Existing
                        </span>
                    </div>

                    <div className='exist-div1'>
                        <div className='exist-div2'>
                            {
                                profile != null
                                    ?
                                    profile.map((elem) => {
                                        return (
                                            <div className='d-flex'>
                                                <div>
                                                    <img className="exist-pic" src={require('../Assets/test_brand.png')} />
                                                </div>

                                                <button className='mx-2 btn' onClick={() => {
                                                    GroupLinks.push([{
                                                        "platform": elem.platform,
                                                        "platform_id": elem.user_id
                                                    }])
                                                }}>
                                                    <div className='exist-text'>@username</div>
                                                    <div>{elem.platform} Profile</div>
                                                </button>
                                            </div>
                                        )
                                    })
                                    :
                                    "Loading...."
                            }
                        </div>
                    </div>

                    <div className='from-div'>
                        <button className='from-btn' onClick={() => { CreateGroup() }}>Proceed</button>
                    </div>
                </Modal>
            </>
            <div>
                <div className='d-flex align-items-center justify-content-between'>
                    <div>
                        <div className='grp-head '>Business Profile & Social Profiles</div>
                    </div>

                    <div className='d-flex align-items-center'>
                        <div className='d-flex align-items-center grphead-btn mx-2'>
                            <div>
                                <img src={require('../Assets/Icons/bookmark.png')} className='grp-vector' />
                            </div>

                            <div>
                                <button className='grp-btn' onClick={showModalNew}>Create a Business Profile</button>
                            </div>
                        </div>
                        <div className='d-flex align-items-center grphead-btn mx-2'>
                            <div>
                                <img src={require('../Assets/Icons/plus.png')} className='grp-vector' />
                            </div>
                            <div>
                                <button className='grp-btn' onClick={showModal}>connect a profile</button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />

                <div className='grp-box1'>
                    <div className='grp-box1-text1'>Organisation Name</div>
                    <div className='grp-box1-text2'>Add, remove and manage your Business profile and social profiles</div>
                    <hr />
                    <div className='grp-box1-text1'>Business Profile</div>
                    <div className='d-flex mt-4'>
                        {
                            Groups != null
                                ?
                                Groups.map((elem) => {
                                    return (
                                        <div className={localStorage.getItem("oolook_default_group") == elem.name ? 'Group_folder_active' : 'Group_folder'} onClick={() => GetGroupDetails(elem.name)}>
                                            {elem.name}
                                        </div>
                                    )
                                })
                                :
                                <Spin indicator={antIcon} />
                        }
                    </div>
                    <hr />

                    {/* <div className='member_count'>2 Team members in <span style={{ color: '#4D94CF' }}>Group Name</span></div>

                    <div>
                        <img src={ProfileImg} className='grp-social' />
                    </div> */}

                    <div className='grp-box1-text1'>
                        {
                            profile
                                ?
                                profile.length
                                :
                                null
                        }
                        {" "}Profile Connected</div>

                    <div className='profile-text'>
                        <ul className='profile_list'>
                            {
                                profile
                                    ?
                                    profile.map((elem) => {
                                        return (
                                            <>
                                                <li>
                                                    <button className='d-flex align-items-center justify-content-between w-100 profile_list_btn'>
                                                        <div className='d-flex align-items-center'>
                                                            {
                                                                elem.platform == "FACEBOOK"
                                                                    ?
                                                                    <img src={`https://graph.facebook.com/${elem.platform_pageId}/picture?return_ssl_resources=true`} className='profile_img' />
                                                                    :
                                                                    elem.platform == "INSTAGRAM"
                                                                        ?
                                                                        <Images widths='3%' Id={elem.platform_id} />
                                                                        :
                                                                        <PinImage Id={elem.platform_id} />
                                                            }
                                                            {
                                                                elem.platform == "FACEBOOK"
                                                                    ?
                                                                    <i className="fa-brands fa-facebook fa-md mx-2" style={{ color: '#3b5998' }}></i>
                                                                    :
                                                                    elem.platform == "LINKEDIN"
                                                                        ?
                                                                        <i className='fa-brands fa-linkedin fa-md mx-2' style={{ color: '#0A66C2' }}></i>
                                                                        :
                                                                        elem.platform == "YOUTUBE"
                                                                            ?
                                                                            <i className='fa-brands fa-youtube fa-md mx-2' style={{ color: '#FF0000' }}></i>
                                                                            :
                                                                            elem.platform == "INSTAGRAM"
                                                                                ?
                                                                                <i className='fa-brands fa-instagram fa-md mx-2' style={{ color: '#E4405F' }}></i>
                                                                                :
                                                                                elem.platform == "TWITTER"
                                                                                    ?
                                                                                    <i className='fa-brands fa-twitter fa-md mx-2' style={{ color: '#1DA1F2' }}></i>
                                                                                    :
                                                                                    <i className='fa-brands fa-pinterest fa-md mx-2' style={{ color: '#E60023' }}></i>
                                                            }
                                                            <span className='profile_name'>
                                                                1 Page Connected
                                                            </span>
                                                        </div>

                                                        <div>
                                                            <button className='mx-1 profile_action_btn'>
                                                                <i class="fa fa-refresh"></i>
                                                            </button>

                                                            <button className='mx-1 profile_action_btn' onClick={() => { DeleteSingleProfile(elem) }}>
                                                                <i class="fa fa-trash" aria-hidden="true"></i>
                                                            </button>
                                                        </div>
                                                    </button>
                                                </li>
                                            </>
                                        )
                                    })
                                    :
                                    "All Available Profiles Connected"
                            }
                        </ul>
                    </div>
                    <div className='profile-text'>
                        <button className='profile-btn' onClick={() => { showModal() }}>Connect a Profile</button>
                    </div>
                </div>

            </div>

            {/* Particular profile modal */}
            <Modal open={groupData} footer={null} onCancel={() => { setGroupData(false) }}>
                <ul className='profile_list'>
                    {
                        singleProfile.length != 0
                            ?
                            (singleProfile).map((elem, index) => {
                                return (
                                    <li key={index}>
                                        <div className='d-flex align-items-center'>
                                            <input type='radio' className='mx-2' name='default' onChange={() => { DefaultAccount(elem); CheckDefault(elem.id); }} />
                                            <img src={elem.picture.data.url != undefined ? elem.picture.data.url : require('../Assets/Insta_logo.png')} className='profile_img' />

                                            <span className='mx-2'>
                                                {elem.name}
                                            </span>

                                            {
                                                CheckDefault(elem)
                                                    ?
                                                    <i className='fa fa-check-circle' style={{ color: '#7FFF00' }} />
                                                    :
                                                    null
                                            }
                                        </div>
                                    </li>
                                )
                            })
                            :
                            null
                    }
                </ul>

                <button className='save_btn' onClick={() => { setGroupData(false) }}>
                    Save
                </button>
            </Modal>
        </>
    )
}

import React, { useEffect } from 'react'
import { Modal, message } from 'antd'
import ConnectModal from './ConnectModal';
import axios from 'axios';
import UserName from '../Comman Components/UserName';
import Images from '../Comman Components/Images';
import InstagramDetails from './InstagramDetails';


export default function AccountProfile() {

    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [profile, setProfile] = React.useState(null);


    const profiles = [
        { id: 1, name: "@hey.ayush", platform: "Instagram", initials: "SP" },
        { id: 2, name: "@hey.tushar", platform: "Facebook", initials: "DJ" },
    ];

    const GetGroupDetails = async () => {

        let token;
        let GroupSelected

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        if (localStorage.getItem("oolook_default_group")) {
            GroupSelected = localStorage.getItem("oolook_default_group")
        }


        await axios.post(`${process.env.REACT_APP_BACKEND_API}/group/get-particular`, {
            groupName: GroupSelected
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                console.log(res.data.group.connected_accounts)
                setProfile(res.data.group.connected_accounts)
                localStorage.setItem("oolook_default_group", GroupSelected)

                // if user vist first time on the page then default group is selected

                if (localStorage.getItem("selected_group") == undefined) {
                    localStorage.setItem("selected_group", GroupSelected)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const DeleteSingleProfile = (pagedetails) => {
        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        axios.post(`${process.env.REACT_APP_BACKEND_API}/group/delete-profile`, {
            platform_id: pagedetails.platform_id
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                GetGroupDetails()
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        GetGroupDetails()
    }, [])

    return (
        <>
            <div className='section_header'>
                <h4>
                    Business Profile
                </h4>
            </div>

            <div className='bg-white pt-5 profile_table_Container'>
                <div className="container ">
                    <div className="card shadow-sm">
                        <div className="card-body">
                            <h5 className="card-title mb-4">Profiles</h5>
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Platform</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        profile != null &&
                                        profile.map((profile) => (
                                            <tr key={profile.id}>
                                                <td>
                                                    <div className="d-flex align-items-center">
                                                        <span style={{ width: '30px' }}>
                                                            {
                                                                profile.platform == "FACEBOOK"
                                                                    ?
                                                                    <img src={`https://graph.facebook.com/${profile.platform_pageId}/picture?return_ssl_resources=true`} style={{ width: '100%' }} className='profile_img_post' />
                                                                    :
                                                                    <Images Id={profile.platform_id} widths="100%" />

                                                            }
                                                        </span>

                                                        <span className='mx-3 row'>
                                                            <span>
                                                                {
                                                                    profile.platform == "INSTAGRAM"
                                                                        ?
                                                                        <UserName Id={profile.platform_id} />
                                                                        :
                                                                        profile.profile
                                                                }
                                                            </span>

                                                            <span>
                                                                {
                                                                    profile.platform == "INSTAGRAM"
                                                                    &&
                                                                    <InstagramDetails UserId={profile.platform_id} />
                                                                }
                                                            </span>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td>{profile.platform}</td>
                                                <td>
                                                    <div className="d-flex gap-3">
                                                        <button className="btn btn-outline-secondary btn-sm">
                                                            <i class="fa fa-refresh"></i>
                                                        </button>
                                                        <button className="btn btn-outline-danger btn-sm" onClick={()=>{DeleteSingleProfile(profile)}}>
                                                            <i class="fa fa-trash"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>

                            <div style={{ textAlign: "right" }}>
                                <button className=" mt-3 profile_connect_btn" onClick={() => { setIsModalOpen(true) }}>+ Connect</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal title="Connect a Profile" open={isModalOpen} footer={null} onCancel={() => { setIsModalOpen(false); }}>
                <ConnectModal />
            </Modal>
        </>
    )
}

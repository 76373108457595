import React, { useEffect } from 'react'
import KonvaDummy from '../Comman Components/combined_data.json'
import { Stage, Layer, Image, Text } from 'react-konva';
import useImage from 'use-image'

export default function Konvatest() {

    const background = KonvaDummy.layers.find(item => item.name === "Background");
    const Logo = KonvaDummy.layers.find(item => item.name === "Logo");
    const Texts = KonvaDummy.layers.filter(item => item.type === "text");


    const [Logoimages] = useImage(Logo.imageAsset);
    const [backgroundImage]=useImage(background.imageAsset)

    const RenderText = (textData) => {
        return (
            <>
            
            <Text
                text={textData.content}
                fontFamily={textData.font != undefined ? textData.font.name : ''}
                x={textData.position != undefined && textData.position.x}
                y={textData.position != undefined && textData.position.y}
                fontSize={textData.font != undefined && textData.font.size}
                width={textData.size != undefined && textData.size.width}
                height={textData.size != undefined && textData.size.height}
                draggable={true}
                fill={textData.font!=undefined&&textData.color}
                fontStyle={textData.font&&textData.style}
            />
            </>
        )
    }

    function LogoRender() {
        return (
            <Image
                x={Logo.commonProperties.position.x}
                y={Logo.commonProperties.position.y}
                width={Logo.imageMetadata.resolution.width}
                height={Logo.imageMetadata.resolution.height}
                image={Logoimages}
                draggable={true}
            />
        )
    }

    return (
        <Stage width={background.commonProperties.size.width} height={background.commonProperties.size.height}>
            <Layer>
                <Image
                    x={background.commonProperties.position.x}
                    y={background.commonProperties.position.y}
                    width={background.imageMetadata.resolution.width}
                    height={background.imageMetadata.resolution.height}
                    image={backgroundImage}
                    draggable={true}
                />
                <LogoRender />
                {
                    Texts.map((elem) => {
                        return (
                            RenderText(elem)
                        )
                    })
                }
            </Layer>
        </Stage>
    )
}

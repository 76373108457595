import React, { useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Link, useNavigate } from 'react-router-dom';
import Dropdown from './Filter Dropdown/Dropdown';
import axios from 'axios';
import { Checkbox, message, Modal } from 'antd'
import GenereatedData from './GenereatedData';
import Images from '../Comman Components/Images';
import UserName from '../Comman Components/UserName';
import PinName from '../Comman Components/Pinterest Componets/PinName';
import PinImage from '../Comman Components/Pinterest Componets/PinImage';
import { toast } from 'react-toastify';

export default function Calender() {
    const [dataObject, setDataObject] = useState(moment());
    const [month, setMonth] = useState('');
    const [ViewType, setViewType] = useState('Month');
    const [GeneratedData, setGeneratedData] = useState(null);
    const [Profilecheck, setProfileCheck] = useState(false);
    const [getPostData, setGetPostData] = useState(null);
    const [TypeFilter, setTypeFilter] = useState(false);
    const [PlatformFilter, setPlatformFilter] = useState(false);
    const [Type, setType] = useState(['Image']);
    const [MediaType, setMediaType] = useState('image');
    const [PlatformType, setPlatformType] = useState(['FACEBOOK', "INSTAGRAM", "PINTEREST"]);
    const [AccountFilter, setAccountFilter] = useState(false);
    const [SelectedPlatform, setSelectedPlatform] = useState(null);
    const [AvaliableAccount, setAvaliableAccount] = useState(null);
    const [showMenu, setShowMenu] = useState(false);
    const [ImportOpen, setImportOpen] = useState(false);
    const [AllGroups, setAllGroups] = useState(null);
    const [showGroups, setShowGroups] = useState(false);
    const [selectedGroup, setSelctedGroup] = useState('');
    const [ImportExcel, setImportExcel] = useState(null);
    const [ScheduledData, setScheduledData] = useState(null);

    const navigatie = useNavigate()

    const [showFilter, setShowFilter] = useState(false);



    const GetProfile = () => {
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        axios.get(`${process.env.REACT_APP_BACKEND_API}/profiles/get-profile`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                if ((res.data.profiles).length == 0) {
                    setProfileCheck(true);
                }
                else {
                    setProfileCheck(false);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const GetPostData = () => {
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }


        let platformSelected = [];

        if (SelectedPlatform != null) {
            SelectedPlatform.map((elem) => {
                if (elem.platform == "FACEBOOK") {
                    platformSelected.push(elem.platform_pageId);
                }
                else {
                    platformSelected.push(elem.platform_id);
                }
            })
        }
        var grpname;
        if(localStorage.getItem('oolook_default_group')==null){
            toast.warning("select group");
        } else {
            grpname=localStorage.getItem('oolook_default_group')
        }


        axios.post(`${process.env.REACT_APP_BACKEND_API}/posts/get-all`, {
            platform: PlatformType,
            groupName: grpname,
            user_id: platformSelected
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })

            .then(response => {
                console.log("checlc",response.data)
                setGetPostData(response.data.Posts);
            })
            .catch(error => {
                console.error("There was an error fetching the posts!", error);
            });

    }

    const firstDayOfMonth = () => {
        let dateObject = dataObject;
        let firstDay = moment(dateObject)
            .startOf("month")
            .format("d");
        return firstDay;
    };

    function WeekRender() {
        const weekdayshort = moment.weekdaysShort();
        let weekdayshortname = weekdayshort.map(day => {
            return (
                <th key={day} className="week-day">
                    {day}
                </th>
            );
        });

        return weekdayshortname;
    }


    function RenderDate() {
        // empty dates

        var lastDates = (moment(`${moment(dataObject).year()}-${moment(dataObject).month()}-01`).endOf('month').format("DD")) - parseInt(firstDayOfMonth());

        let blanks = [];
        for (let i = 0; i < firstDayOfMonth(); i++) {
            blanks.push(
                <td className="calendar-day previous_dates empty"><div>{lastDates + i + 1}</div></td>
            )
        }

        // dates
        let dates = moment(dataObject)
            .daysInMonth();
        const currentMonth = new Date().getMonth() + 1;
        let date = [];
        let nextDates = [];


        for (var d = 1; d <= dates; d++) {
            const postForTheDay = getPostData.filter(post => moment(post.date).date() === d && moment(post.date).month() === dataObject.month());

            var scheduledDates;
            var importedPosts = {};


            if (ScheduledData != null) {
                scheduledDates = ScheduledData.filter(post => moment((post.scheduledTime).split("T")[0]).date() === d && moment((post.scheduledTime).split("T")[0]).month() === dataObject.month());

                // console.log(scheduledDates)

                scheduledDates.forEach((item) => {
                    item.platforms.forEach((platform) => {
                        const upperPlatform = platform.toUpperCase();
                        if (importedPosts[platform]) {
                            importedPosts[platform]++;
                            importedPosts[platform].items.push(item)
                            // importedPosts
                        } else {
                            // importedPosts[platform] = 1;
                            importedPosts[platform] = {
                                count: 1,
                                items: [item]
                            };
                        }
                    });
                });

            }


            const groupedPosts = postForTheDay.reduce((acc, post) => {
                if (!acc[post.platform]) {
                    acc[post.platform] = [];
                }
                acc[post.platform].push(post);
                return acc;
            }, {});


            if (GeneratedData == null) {
                if (d == dataObject.format("D")) {
                    if (parseInt(dataObject.format("M")) != currentMonth) {
                        date.push(
                            <th key={d} className="weeks-day">
                                <div style={{ textAlign: "left", height: "10%", marginBottom: '10px' }}>
                                    {d}

                                    <div className='mt-2 mx-2 d-flex flex-column'>

                                        {
                                            Object.entries(importedPosts).map((platform) => {
                                                return (
                                                    <span className='posts_icons' key={platform}
                                                        onClick={() => { navigatie('/Dashboard/Approval', { state: { genData: { "platform": platform[0], "GroupData": platform[1].items  } } }) }}
                                                    >
                                                        {
                                                            platform[0] == "FACEBOOK"
                                                                ?
                                                                <>
                                                                    <i class="fa-brands fa-facebook"></i>
                                                                    {platform[1].count}
                                                                </>
                                                                :
                                                                null
                                                        }

                                                        {
                                                            platform[0] == "INSTAGRAM"
                                                                ?
                                                                <>
                                                                    <i class="fa-brands fa-instagram"></i>
                                                                    {platform[1].count}
                                                                </>
                                                                :
                                                                null
                                                        }

                                                        {
                                                            platform[0] == "PINTEREST"
                                                                ?
                                                                <>
                                                                    <i class="fa-brands fa-pinterest"></i>
                                                                    {platform[1].count}
                                                                </>
                                                                :
                                                                null
                                                        }
                                                    </span>
                                                )
                                            })
                                        }

                                        {Object.keys(groupedPosts).map(platform =>
                                        (
                                            <span className='posts_icons' key={platform}
                                                onClick={() => { navigatie('/Dashboard/Approval', { state: { genData: { "platform": platform, "GroupData": groupedPosts[platform] } } }) }}
                                            >
                                                {
                                                    platform == "FACEBOOK"
                                                        ?
                                                        <i class="fa-brands fa-facebook"></i>
                                                        :
                                                        null
                                                }

                                                {
                                                    platform == "INSTAGRAM"
                                                        ?
                                                        <i class="fa-brands fa-instagram"></i>
                                                        :
                                                        null
                                                }

                                                {
                                                    platform == "PINTEREST"
                                                        ?
                                                        <i class="fa-brands fa-pinterest"></i>
                                                        :
                                                        null
                                                }

                                                {groupedPosts[platform].length}
                                            </span>
                                        ))}
                                    </div>
                                </div>

                                {/* task */}
                                <div style={{ height: "90%" }}>

                                    <div className='action_task'>
                                        <button id={d} className='tasks add_task' onClick={(e) => navigatie('/Dashboard/PublishPost', { state: { date: parseInt(e.target.id), month: moment(dataObject).month() } })}>
                                            +{/* <i class="fa fa-plus" aria-hidden="true"></i> */}
                                        </button>

                                        <button className='tasks add_task'>
                                            <i class='fa fa-edit'></i>
                                        </button>
                                    </div>
                                </div>
                            </th>
                        )
                    }
                    else {
                        date.push(
                            <th key={d} className="weeks-day current">
                                <div id="render_date" style={{ textAlign: "left", height: "10%", marginBottom: '10px' }} className='d-flex flex-column'>
                                    {d}

                                    <div className='mt-2 mx-2 d-flex flex-column'>

                                        {
                                            Object.entries(importedPosts).map((platform) => {
                                                return (
                                                    <span className='posts_icons' key={platform}
                                                        onClick={() => { navigatie('/Dashboard/Approval', { state: { genData: { "platform": platform[0], "GroupData": platform[1].items } } }) }}
                                                    >
                                                        {
                                                            platform[0] == "FACEBOOK"
                                                                ?
                                                                <>
                                                                    <i class="fa-brands fa-facebook"></i>
                                                                    {platform[1].count}
                                                                </>
                                                                :
                                                                null
                                                        }

                                                        {
                                                            platform[0] == "INSTAGRAM"
                                                                ?
                                                                <>
                                                                    <i class="fa-brands fa-instagram"></i>
                                                                    {platform[1].count}
                                                                </>
                                                                :
                                                                null
                                                        }

                                                        {
                                                            platform[0] == "PINTEREST"
                                                                ?
                                                                <>
                                                                    <i class="fa-brands fa-pinterest"></i>
                                                                    {platform[1].count}
                                                                </>
                                                                :
                                                                null
                                                        }
                                                    </span>
                                                )
                                            })
                                        }

                                        {Object.keys(groupedPosts).map(platform => (
                                            <span className='posts_icons' key={platform}
                                                onClick={() => { navigatie('/Dashboard/Approval', { state: { genData: { "platform": platform, "GroupData": groupedPosts[platform] } } }) }}
                                            >
                                                {
                                                    platform == "FACEBOOK"
                                                        ?
                                                        <i class="fa-brands fa-facebook"></i>
                                                        :
                                                        null
                                                }

                                                {
                                                    platform == "INSTAGRAM"
                                                        ?
                                                        <i class="fa-brands fa-instagram"></i>
                                                        :
                                                        null
                                                }

                                                {
                                                    platform == "PINTEREST"
                                                        ?
                                                        <i class="fa-brands fa-pinterest"></i>
                                                        :
                                                        null
                                                }

                                                {groupedPosts[platform].length}
                                            </span>
                                        ))}
                                    </div>
                                </div>

                                {/* task */}
                                <div style={{ height: "90%", }}>

                                    <div className='action_task'>
                                        <button id={d} className='tasks add_task' onClick={(e) => { navigatie('/Dashboard/PublishPost', { state: { date: parseInt(e.target.id), month: moment(dataObject).month() } }); }}>
                                            +{/* <i class="fa fa-plus" aria-hidden="true"></i> */}
                                        </button>

                                        <span className='tasks add_task'>
                                            <i class='fa fa-edit'></i>
                                        </span>
                                    </div>
                                </div>
                            </th>
                        )
                    }
                }
                else
                    if (d < dataObject.format("D")) {
                        date.push(
                            <th key={d} className="previous-weeks-day">
                                <div style={{ textAlign: "left", height: "10%", marginBottom: '10px' }} className='d-flex flex-column'>
                                    {d}

                                    <div className='mt-2 mx-2 d-flex flex-column'>
                                        {
                                            Object.entries(importedPosts).map((platform) => {
                                                return (
                                                    <span className='posts_icons' key={platform}
                                                        onClick={() => { navigatie('/Dashboard/Approval', { state: { genData: { "platform": platform[0], "GroupData": platform[1].items  } } }) }}
                                                    >
                                                        {
                                                            platform[0] == "FACEBOOK"
                                                                ?
                                                                <>
                                                                    <i class="fa-brands fa-facebook"></i>
                                                                    {platform[1].count}
                                                                </>
                                                                :
                                                                null
                                                        }

                                                        {
                                                            platform[0] == "INSTAGRAM"
                                                                ?
                                                                <>
                                                                    <i class="fa-brands fa-instagram"></i>
                                                                    {platform[1].count}
                                                                </>
                                                                :
                                                                null
                                                        }

                                                        {
                                                            platform[0] == "PINTEREST"
                                                                ?
                                                                <>
                                                                    <i class="fa-brands fa-pinterest"></i>
                                                                    {platform[1].count}
                                                                </>
                                                                :
                                                                null
                                                        }
                                                    </span>
                                                )
                                            })
                                        }

                                        {Object.keys(groupedPosts).map(platform =>
                                        (
                                            <span className='posts_icons' key={platform}
                                                onClick={() => { navigatie('/Dashboard/Approval', { state: { genData: { "platform": platform, "GroupData": groupedPosts[platform] } } }) }}
                                            >
                                                {
                                                    platform == "FACEBOOK"
                                                        ?
                                                        <i class="fa-brands fa-facebook"></i>
                                                        :
                                                        null
                                                }

                                                {
                                                    platform == "INSTAGRAM"
                                                        ?
                                                        <i class="fa-brands fa-instagram"></i>
                                                        :
                                                        null
                                                }

                                                {
                                                    platform == "PINTEREST"
                                                        ?
                                                        <i class="fa-brands fa-pinterest"></i>
                                                        :
                                                        null
                                                }

                                                {groupedPosts[platform].length}
                                            </span>
                                        ))}

                                    </div>

                                </div>


                                {/* task */}
                                <div style={{ height: "90%" }}>

                                    <div className='action_task'>
                                        {
                                            d < dataObject.format("D")
                                                ?
                                                null
                                                :
                                                <>
                                                    <button id={d} className='tasks add_task' onClick={(e) => { navigatie('/Dashboard/PublishPost', { state: { date: parseInt(e.target.id), month: moment(dataObject).month() } }); }}>
                                                        +  {/* <i class="fa fa-plus" aria-hidden="true"></i> */}
                                                    </button>
                                                    <span className='tasks add_task'>
                                                        <i class='fa fa-edit'></i>
                                                    </span>
                                                </>
                                        }



                                    </div>
                                </div>
                            </th>
                        )
                    }
                    else {
                        date.push(
                            <th key={d} className="weeks-day">
                                <div style={{ textAlign: "left", height: "10%", marginBottom: '10px' }}>
                                    {d}

                                    <div className='mt-2 mx-2 d-flex flex-column'>
                                        {
                                            Object.entries(importedPosts).map((platform) => {
                                                return (
                                                    <span className='posts_icons' key={platform}
                                                        onClick={() => {
                                                            navigatie('/Dashboard/Approval', { state: { genData: { "platform": platform[0], "GroupData": platform[1].items } } })
                                                        }}
                                                    >
                                                        {
                                                            platform[0] == "FACEBOOK"
                                                                ?
                                                                <>
                                                                    <i class="fa-brands fa-facebook"></i>
                                                                    {platform[1].count}
                                                                </>
                                                                :
                                                                null
                                                        }

                                                        {
                                                            platform[0] == "INSTAGRAM"
                                                                ?
                                                                <>
                                                                    <i class="fa-brands fa-instagram"></i>
                                                                    {platform[1].count}
                                                                </>
                                                                :
                                                                null
                                                        }

                                                        {
                                                            platform[0] == "PINTEREST"
                                                                ?
                                                                <>
                                                                    <i class="fa-brands fa-pinterest"></i>
                                                                    {platform[1].count}
                                                                </>
                                                                :
                                                                null
                                                        }
                                                    </span>
                                                )
                                            })
                                        }
                                        {Object.keys(groupedPosts).map(platform => (
                                            <span className='posts_icons' key={platform}
                                                onClick={() => {
                                                    // navigatie('/Dashboard/Approval', { state: { genData: { "platform": platform, "GroupData": groupedPosts[platform] } } }) 
                                                }}
                                            >
                                                {
                                                    platform == "FACEBOOK"
                                                        ?
                                                        <i class="fa-brands fa-facebook"></i>
                                                        :
                                                        null
                                                }

                                                {
                                                    platform == "INSTAGRAM"
                                                        ?
                                                        <i class="fa-brands fa-instagram"></i>
                                                        :
                                                        null
                                                }

                                                {
                                                    platform == "PINTEREST"
                                                        ?
                                                        <i class="fa-brands fa-pinterest"></i>
                                                        :
                                                        null
                                                }

                                                {groupedPosts[platform].length}
                                            </span>
                                        ))}
                                    </div>
                                </div>

                                {/* task */}
                                <div style={{ height: "90%" }}>

                                    <div className='action_task'>
                                        {
                                            d < dataObject.format("D")
                                                ?
                                                null
                                                :
                                                <>
                                                    <button id={d} className='tasks add_task' onClick={(e) => { navigatie('/Dashboard/PublishPost', { state: { date: parseInt(e.target.id), month: moment(dataObject).month() } }); }}>
                                                        +{/* <i class="fa fa-plus" aria-hidden="true"></i> */}
                                                    </button>
                                                    <span className='tasks add_task'>
                                                        <i class='fa fa-edit'></i>
                                                    </span>
                                                </>
                                        }

                                    </div>
                                </div>
                            </th >
                        )
                    }
            }
            else {
                date.push(
                    <GenereatedData date={d} PostedData={groupedPosts} importedPosts={importedPosts} dataObject={dataObject} month={moment(dataObject).month() + 1} genratedData={GeneratedData} />
                )
            }
        }


        var totalSlots = [...blanks, ...date];
        let rows = [];
        let cells = [];

        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row); // if index not equal 7 that means not go to next week
            } else {
                rows.push(cells); // when reach next week we contain all td in last week to rows 
                cells = []; // empty container 
                cells.push(row); // in current loop we still push current row to new container
            }
            if (i === totalSlots.length - 1) { // when end loop we add remain date
                rows.push(cells);
            }
        })

        let daysinmonth = rows.map((d, i) => {
            return <tr>{d}</tr>;
        });


        return daysinmonth;
    }

    const NextMonth = () => {
        setMonth(Months[moment(month).month() + 1] + ", " + moment().year());
        setDataObject(moment(Months[moment(month).month() + 1] + ", " + moment().year()))
    }

    const PreviousMonth = () => {
        setMonth(Months[moment(month).month() - 1] + ", " + moment().year());
        setDataObject(moment(Months[moment(month).month() - 1] + ", " + moment().year()))

    }

    const currentMonth = () => {
        setMonth(Months[moment().month()] + ", " + moment().year());
        setDataObject(moment())
    }

    const Months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const FilterData = [
        [
            "Type", [
                "Image",
                "Video"
            ]
        ],
        [
            "Profiles", AvaliableAccount
        ],
        [
            "Platform", [
                "FACEBOOK",
                "INSTAGRAM",
                "PINTEREST"
            ]
        ]
    ];

    const Generate = () => {
        var token;
        let emails;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }


        axios.post(`${process.env.REACT_APP_BACKEND_API}/task/generate`, {}, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setGeneratedData(res.data.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const GetGenerateData = () => {
        var token;

        if (localStorage.getItem('user_Token')) {
            token = localStorage.getItem('user_Token');
        }
        else {
            token = sessionStorage.getItem('user_Token');
        }

        axios.get(`${process.env.REACT_APP_BACKEND_API}/task/get`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                if ((res.data.tasks).length == 0) {
                    console.log(res.data.tasks)
                }
                else {
                    console.log(res.data.tasks[0].details)
                    setGeneratedData(res.data.tasks[0].details)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Post Type Filter
    const ChangeType = (FilterType, checked) => {
        if (checked.target.checked == true) {
            if (FilterType == "Image") {
                Type.push("Image");
                if (Type.length == 2) {
                    setMediaType('');
                    GetPostData();
                }
                else {
                    setMediaType('image');
                    GetPostData();
                }

                setTypeFilter(false)
            }
            else {
                Type.push("Video");
                if (Type.length == 2) {
                    setMediaType('');
                    GetPostData();
                }
                else {
                    setMediaType('video');
                    GetPostData();
                }

                setTypeFilter(false)
            }
        }
        else {
            if (FilterType == "Image") {
                Type.splice(Type.indexOf("Image"), 1);
                setTypeFilter(false);
                GetPostData();
            }
            else {
                Type.splice(Type.indexOf("Video"), 1);
                setTypeFilter(false);
                GetPostData();
            }
        }
    }

    // Platform Filter
    const ChangePlatform = (FilterType, checked) => {
        console.log(FilterType, checked.target.checked)
        if (checked.target.checked == false) {
            PlatformType.map((elem) => {
                if (elem == FilterType) {
                    PlatformType.splice(PlatformType.indexOf(JSON.parse(JSON.stringify(FilterType).toUpperCase())), 1);
                    setPlatformFilter(false);
                    GetPostData()
                }
            })
        }
        else {
            PlatformType.push(FilterType);
            setPlatformFilter(false);
            GetPostData();
        }
    }

    // Get particular Groups
    const GetGroups = () => {
        var token;
        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        axios.post(`${process.env.REACT_APP_BACKEND_API}/group/get-particular`, {
            groupName: localStorage.getItem("oolook_default_group")
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setAvaliableAccount(res.data.group.connected_accounts)

                var selePlatform;

                selePlatform = (res.data.group.connected_accounts).slice(0);

                const indexToRemove = selePlatform.findIndex(item => item.platform === 'PINTEREST');

                // If a Pinterest object is found, remove it
                if (indexToRemove !== -1) {
                    selePlatform.splice(indexToRemove, 1);
                }

                setSelectedPlatform(selePlatform);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Get all groups
    const getallGroups = async () => {
        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        await axios.get(`${process.env.REACT_APP_BACKEND_API}/group/get`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setAllGroups(res.data.groups);
                setSelctedGroup(res.data.groups[0].name)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Account Filter
    const CheckDefaultSelect = (Account) => {
        if (Account.platform == "FACEBOOK") {
            for (var i = 0; i < SelectedPlatform.length; i++) {
                if (SelectedPlatform[i].platform_pageId == Account.platform_pageId) {
                    return true;
                }
            }
        }
        else {
            for (var i = 0; i < SelectedPlatform.length; i++) {
                if (SelectedPlatform[i].platform_id == Account.platform_id) {
                    return true;
                }
            }
        }
    }

    const ChangePaltform = (value, checked) => {
        if (checked) {
            SelectedPlatform.push(value);
            GetPostData()
        }
        else {
            if (value.platform == "FACEBOOK") {
                SelectedPlatform.map(item => {
                    if (item.platform_pageId === value.platform_pageId) {
                        SelectedPlatform.splice(SelectedPlatform.indexOf(value), 1);
                        GetPostData()
                        // console.log(item.platform_id);
                    }
                })
            }
            if (value.platform == "INSTAGRAM") {
                SelectedPlatform.map(item => {
                    if (item.platform_id === value.platform_id) {
                        SelectedPlatform.splice(SelectedPlatform.indexOf(value), 1);
                        GetPostData()
                    }
                })
            }
            if (value.platform == "PINTEREST") {
                SelectedPlatform.map(item => {
                    if (item.platform_id === value.platform_id) {
                        SelectedPlatform.splice(SelectedPlatform.indexOf(value), 1);
                        GetPostData()
                    }
                })
            }
        }
    }

    // download sample file
    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = '/Samplefile.xlsx'; // Replace with the actual file URL or path
        link.download = 'Samplefiles.xlsx'; // The name for the downloaded file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // Import File
    const ImportFile = async () => {
        const formData = new FormData();
        formData.append('file', ImportExcel[0]);
        formData.append('groupName', selectedGroup);

        SelectedPlatform.map((elem) => {
            formData.append('selectedProfiles', elem.platform_id);
        });

        PlatformType.map((elem) => {
            formData.append('platforms', elem)
        });

        var token;
        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }


        await axios.post(`${process.env.REACT_APP_BACKEND_API}/schedule/upload-schedules`, formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                message.success("File Imported");
                setImportOpen(false);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // Get All ScheduledData
    const GetScheduledData = async () => {
        var token;
        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        await axios.get(`${process.env.REACT_APP_BACKEND_API}/schedule/get-all/${localStorage.getItem("oolook_default_group")}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                console.log('cel check',res.data.scheduled_posts)
                setScheduledData(res.data.scheduled_posts);
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        setMonth(Months[moment(dataObject).month()] + ", " + moment().year());
        GetGenerateData()
        GetProfile()
        GetGroups()
        getallGroups();
        GetScheduledData();
    }, [])

    useEffect(() => {
        GetPostData();
    }, [SelectedPlatform])

    if (Profilecheck) {
        return (
            <div>
                <div id="overlay"></div>
                <div className='d-flex justify-content-center align-items-center'>
                    <h1 className='warning_msg'>Connect atleast one profile</h1>
                </div>
            </div>
        )
    }


    return (
        <div className='calender_section'>
            <div className='d-flex align-items-center justify-content-between'>
                <div className='approval_header d-flex'>
                    <button className='arrow_btn' onClick={() => { PreviousMonth(); }}>
                        <i class="fa fa-arrow-left" aria-hidden="true"></i>
                    </button>

                    <h4 className='px-3 current_month' onClick={() => { currentMonth() }}>{"Today"}</h4>

                    <button className='arrow_btn' style={{ marginRight: '10px' }} onClick={() => { NextMonth(); }}>
                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </button>

                    <h5>{month}</h5>

                </div>

                <div className='d-flex'>
                    {/* <div className='d-flex menu_section mx-2'>
                        <button className='menu_btn_1 menu_active'>
                            <img src={require('../Assets/menu_1.png')} />
                        </button>

                        <button className='menu_btn_2'>
                            <img src={require('../Assets/menu_2.png')} />
                        </button>
                    </div> */}

                    <div className='d-flex justify-content-between menu_section mx-2'>
                        <button className={ViewType == "List" ? 'menu_btn_1 menu_active' : 'menu_btn_1 '} onClick={() => { setViewType('List') }}>
                            List
                        </button>

                        <button className={ViewType == "Month" ? 'menu_btn_2 menu_active' : 'menu_btn_2'} onClick={() => { setViewType('Month') }}>
                            Month
                        </button>
                    </div>

                    <div className='flex-column d-flex mt-1'>
                        <div className='d-flex justify-content-between dot_menu menu_section mx-2'>
                            <button className='menu_btn_2' onClick={() => { setShowMenu(!showMenu) }}>
                                <i class="fa fa-ellipsis-h"></i>
                            </button>
                        </div>

                        <>
                            {
                                showMenu
                                    ?
                                    <div className='list_menu'>
                                        <ul>
                                            <li>
                                                <button onClick={() => { setImportOpen(true); setShowMenu(false); }}>
                                                    Import File
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                    :
                                    null
                            }
                        </>
                    </div>

                    <>
                        <Modal
                            open={ImportOpen}
                            onCancel={() => setImportOpen(false)}
                            footer={null}
                        >
                            <div>
                                <table className='table'>
                                    <tr>
                                        <td>
                                            Upload File

                                            <button className='download_file' onClick={() => { handleDownload() }}>
                                                <i class="fa fa-info-circle fa-sm" title="download sample format file" aria-hidden="true"></i>
                                            </button>
                                        </td>

                                        <td>
                                            <input type="file" onChange={(e) => { setImportExcel(e.target.files) }} accept=".xls,.xlsx" />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            Groups:
                                        </td>

                                        <td>
                                            <div style={{ width: '33.3%', marginRight: '10px' }}>
                                                <button className='select_filter Group_name_filter py-2 d-flex align-items-center justify-content-between' onClick={() => { setShowGroups(!showGroups) }}>
                                                    {selectedGroup}
                                                    {
                                                        showGroups
                                                            ?
                                                            <i class="fa fa-angle-up" style={{ color: "#000", fontSize: '15px' }}></i>
                                                            :
                                                            <i class="fa fa-angle-down" style={{ color: "#000", fontSize: '15px' }}></i>

                                                    }
                                                </button>

                                                {
                                                    showGroups
                                                        ?
                                                        <div className='option_list'>
                                                            <ul>
                                                                {
                                                                    AllGroups != null
                                                                        ?
                                                                        AllGroups.map((elem) => {
                                                                            return (
                                                                                <li onClick={() => setSelctedGroup(elem.name)}>
                                                                                    {elem.name}
                                                                                </li>
                                                                            )
                                                                        })
                                                                        :
                                                                        null
                                                                }
                                                            </ul>
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            Select Profiles
                                        </td>

                                        <td>
                                            <div style={{ width: '33.3%', marginRight: '10px' }}>
                                                <button className='d-flex align-items-center justify-content-between select_filter' onClick={() => { setAccountFilter(!AccountFilter) }}>
                                                    <div className='d-flex flex-column mx-2'>
                                                        <div className='filter_head'>
                                                            {FilterData[1][0]}
                                                        </div>

                                                        {/* Options */}
                                                        {
                                                            SelectedPlatform != null
                                                                ?
                                                                <div className='filter_selcted_option'>
                                                                    {
                                                                        SelectedPlatform.length != 1
                                                                            ?
                                                                            SelectedPlatform.length - 1 + "+" + " Profiles"
                                                                            :
                                                                            SelectedPlatform
                                                                    }{" "}
                                                                </div>
                                                                :
                                                                null
                                                        }
                                                    </div>

                                                    <div>
                                                        <i class={AccountFilter ? "fa fa-angle-up" : "fa fa-angle-down"}></i>
                                                    </div>
                                                </button>

                                                {
                                                    AccountFilter
                                                        ?
                                                        <div className='option_list'>
                                                            <ul>
                                                                {
                                                                    AvaliableAccount.map((elem, index) => {
                                                                        return (
                                                                            <li>
                                                                                <div className='d-flex align-items-center justify-content-between'>
                                                                                    <div className='d-flex align-items-center w-100'>
                                                                                        {
                                                                                            elem.platform == "FACEBOOK"
                                                                                                ?
                                                                                                <img src={`https://graph.facebook.com/${elem.platform_pageId}/picture?return_ssl_resources=true`} className='filter_profile_img' />
                                                                                                :
                                                                                                elem.platform == "INSTAGRAM"
                                                                                                    ?
                                                                                                    <>
                                                                                                        {<Images Id={elem.platform_id} />}
                                                                                                    </>
                                                                                                    :
                                                                                                    <PinImage Id={elem.platform_id} />
                                                                                        }
                                                                                        {
                                                                                            elem.platform == "FACEBOOK"
                                                                                                ?
                                                                                                <i class="fa-brands fa-facebook mx-2" style={{ color: '#1877F2' }}></i>
                                                                                                :
                                                                                                elem.platform == "INSTAGRAM"
                                                                                                    ?
                                                                                                    <i class="fa-brands fa-instagram mx-2" style={{ color: '#E1306C' }}></i>
                                                                                                    :
                                                                                                    elem.platform == "PINTEREST"
                                                                                                        ?
                                                                                                        <i class="fa-brands fa-pinterest mx-2" style={{ color: '#E60023' }}></i>
                                                                                                        :
                                                                                                        null
                                                                                        }
                                                                                        {
                                                                                            elem.platform == "FACEBOOK"
                                                                                                ?
                                                                                                <>
                                                                                                    {elem.profile}
                                                                                                </>
                                                                                                :
                                                                                                elem.platform == "INSTAGRAM"
                                                                                                    ?
                                                                                                    <UserName Id={elem.platform_id} />
                                                                                                    :
                                                                                                    <PinName Id={elem.platform_id} />
                                                                                        }
                                                                                    </div>

                                                                                    <div>
                                                                                        <input type="checkbox" defaultChecked={CheckDefaultSelect(elem)} onChange={(e) => { ChangePaltform(elem, e.target.checked) }} />
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            Platforms
                                        </td>

                                        <td>
                                            <div style={{ width: '33.3%', marginRight: '10px' }}>
                                                <button className='d-flex align-items-center justify-content-between select_filter' onClick={() => { setPlatformFilter(!PlatformFilter) }}>
                                                    <div className='d-flex flex-column mx-2'>
                                                        <div className='filter_head'>
                                                            {FilterData[2][0]}
                                                        </div>

                                                        {/* Options */}
                                                        <div className='filter_selcted_option'>
                                                            {
                                                                PlatformType.length != 1
                                                                    ?
                                                                    PlatformType.length - 1 + "+" + " Platforms"
                                                                    :
                                                                    PlatformType
                                                            }{" "}
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <i class={TypeFilter ? "fa fa-angle-up" : "fa fa-angle-down"}></i>
                                                    </div>
                                                </button>

                                                {
                                                    PlatformFilter
                                                        ?
                                                        <div className='option_list'>
                                                            <ul>
                                                                {
                                                                    FilterData[2][1].map((elem) => {
                                                                        return (
                                                                            <li>
                                                                                <div className='d-flex align-items-center'>
                                                                                    <Checkbox
                                                                                        key={elem}
                                                                                        value={elem}
                                                                                        checked={PlatformType.includes(elem)}
                                                                                        onClick={(e) => { ChangePlatform(elem, e) }}
                                                                                    >
                                                                                        {elem}
                                                                                    </Checkbox>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                </table>

                                <div>
                                    <button className='import_btn' onClick={() => { ImportFile() }}>
                                        Import Data
                                    </button>
                                </div>
                            </div>
                        </Modal>
                    </>

                    <div className='d-flex justify-content-between align-items-center px-2 menu_section mx-2'>
                        <img src={require('../Assets/share.png')} style={{ width: '20px', height: '20px' }} />
                        <button className='menu_btn_2'>
                            Share
                        </button>

                    </div>

                    <div className='d-flex justify-content-between align-items-center px-2 menu_section mx-2 menu_active'>

                        <button className='menu_btn_2 ' style={{ color: "#fff" }} onClick={() => { Generate() }}>
                            Generate
                        </button>

                        <img src={require('../Assets/generate.png')} style={{ width: '20px', height: '20px' }} />
                    </div>
                </div>
            </div>

            {/* Filter List */}
            <div className='d-flex align-items-center justify-content-between filter_section'>
                {/* Posting Type Filter */}
                <div style={{ width: '33.3%', marginLeft: '10px', marginRight: '10px' }}>
                    <button className='d-flex align-items-center justify-content-between select_filter' onClick={() => { setTypeFilter(!TypeFilter) }}>
                        <div className='d-flex flex-column mx-2'>
                            <div className='filter_head'>
                                {FilterData[0][0]}
                            </div>

                            {/* Options */}
                            <div className='filter_selcted_option'>
                                {
                                    Type.length != 1
                                        ?
                                        "Image, Video"
                                        :
                                        Type
                                }{" "}
                            </div>
                        </div>

                        <div>
                            <i class={TypeFilter ? "fa fa-angle-up" : "fa fa-angle-down"}></i>
                        </div>
                    </button>

                    {/* options list */}
                    {
                        TypeFilter
                            ?
                            <div className='option_list'>
                                <ul>
                                    {
                                        FilterData[0][1].map((elem) => {
                                            return (
                                                <li>
                                                    <div className='d-flex align-items-center'>
                                                        <Checkbox
                                                            key={elem}
                                                            value={elem}
                                                            checked={Type.includes(elem)}
                                                            onClick={(e) => { ChangeType(elem, e) }}
                                                        >
                                                            {elem}
                                                        </Checkbox>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            :
                            null
                    }
                </div>

                {/* Platform Filter */}
                <div style={{ width: '33.3%', marginLeft: '10px', marginRight: '10px' }}>
                    <button className='d-flex align-items-center justify-content-between select_filter' onClick={() => { setPlatformFilter(!PlatformFilter) }}>
                        <div className='d-flex flex-column mx-2'>
                            <div className='filter_head'>
                                {FilterData[2][0]}
                            </div>

                            {/* Options */}
                            <div className='filter_selcted_option'>
                                {
                                    PlatformType.length != 1
                                        ?
                                        PlatformType.length - 1 + "+" + " Platforms"
                                        :
                                        PlatformType
                                }{" "}
                            </div>
                        </div>

                        <div>
                            <i class={TypeFilter ? "fa fa-angle-up" : "fa fa-angle-down"}></i>
                        </div>
                    </button>

                    {
                        PlatformFilter
                            ?
                            <div className='option_list'>
                                <ul>
                                    {
                                        FilterData[2][1].map((elem) => {
                                            return (
                                                <li>
                                                    <div className='d-flex align-items-center'>
                                                        <Checkbox
                                                            key={elem}
                                                            value={elem}
                                                            checked={PlatformType.includes(elem)}
                                                            onClick={(e) => { ChangePlatform(elem, e) }}
                                                        >
                                                            {elem}
                                                        </Checkbox>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            :
                            null
                    }
                </div>

                {/* Account Filter */}
                <div style={{ width: '33.3%', marginLeft: '10px', marginRight: '10px' }}>
                    <button className='d-flex align-items-center justify-content-between select_filter' onClick={() => { setAccountFilter(!AccountFilter) }}>
                        <div className='d-flex flex-column mx-2'>
                            <div className='filter_head'>
                                {FilterData[1][0]}
                            </div>

                            {/* Options */}
                            {
                                SelectedPlatform != null
                                    ?
                                    <div className='filter_selcted_option'>
                                        {
                                            SelectedPlatform.length != 1
                                                ?
                                                SelectedPlatform.length - 1 + "+" + " Profiles"
                                                :
                                                SelectedPlatform
                                        }{" "}
                                    </div>
                                    :
                                    null
                            }
                        </div>

                        <div>
                            <i class={AccountFilter ? "fa fa-angle-up" : "fa fa-angle-down"}></i>
                        </div>
                    </button>

                    {
                        AccountFilter
                            ?
                            <div className='option_list'>
                                <ul>
                                    {
                                        AvaliableAccount.map((elem, index) => {
                                            return (
                                                <li>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div className='d-flex align-items-center w-100'>
                                                            {
                                                                elem.platform == "FACEBOOK"
                                                                    ?
                                                                    <img src={`https://graph.facebook.com/${elem.platform_pageId}/picture?return_ssl_resources=true`} className='filter_profile_img' />
                                                                    :
                                                                    elem.platform == "INSTAGRAM"
                                                                        ?
                                                                        <>
                                                                            {<Images Id={elem.platform_id} />}
                                                                        </>
                                                                        :
                                                                        <PinImage Id={elem.platform_id} />
                                                            }
                                                            {
                                                                elem.platform == "FACEBOOK"
                                                                    ?
                                                                    <i class="fa-brands fa-facebook mx-2" style={{ color: '#1877F2' }}></i>
                                                                    :
                                                                    elem.platform == "INSTAGRAM"
                                                                        ?
                                                                        <i class="fa-brands fa-instagram mx-2" style={{ color: '#E1306C' }}></i>
                                                                        :
                                                                        elem.platform == "PINTEREST"
                                                                            ?
                                                                            <i class="fa-brands fa-pinterest mx-2" style={{ color: '#E60023' }}></i>
                                                                            :
                                                                            null
                                                            }
                                                            {
                                                                elem.platform == "FACEBOOK"
                                                                    ?
                                                                    <>
                                                                        {elem.profile}
                                                                    </>
                                                                    :
                                                                    elem.platform == "INSTAGRAM"
                                                                        ?
                                                                        <UserName Id={elem.platform_id} />
                                                                        :
                                                                        <PinName Id={elem.platform_id} />
                                                            }
                                                        </div>

                                                        <div>
                                                            <input type="checkbox" defaultChecked={CheckDefaultSelect(elem)} onChange={(e) => { ChangePaltform(elem, e.target.checked) }} />
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                            :
                            null
                    }
                </div>


            </div>


            <div className='days_section'>
                <table>
                    <thead>
                        {
                            WeekRender()
                        }
                    </thead>
                    <tbody>
                        {
                            getPostData != null
                                ?
                                RenderDate()
                                :
                                null
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

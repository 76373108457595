import axios from 'axios'
import React, { useEffect, useState } from 'react'

export default function InstagramDetails({ UserId }) {

    const [UserDetails, setUserDetails] = useState(null);

    const GetInstagramDetails = async () => {
        let token;

        if (localStorage.getItem("user_Token")) {
            token = localStorage.getItem("user_Token");
        }
        else {
            token = sessionStorage.getItem("user_Token");
        }

        await axios.post(`${process.env.REACT_APP_BACKEND_API}/instagram/profile`, {
            insta_id: UserId
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => {
                setUserDetails(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        GetInstagramDetails()
    }, [])

    return (
        <div className='mt-2'>
            <span className='Insta_details_txt'>
                Followers:
            </span>

            <span className='Insta_details_txt'>
                {
                    UserDetails && UserDetails.followers_count
                }
            </span>

            <span className='Insta_details_txt'>
                , Following:
            </span>

            <span className='Insta_details_txt'>
                {
                    UserDetails && UserDetails.follows_count
                }
            </span>

            <span className='Insta_details_txt'>
                , Posts:
            </span>

            <span className='Insta_details_txt'>
                {
                    UserDetails && UserDetails.media_count
                }
            </span>
        </div>
    )
}
